
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue'
import { ellipsisVertical, add } from 'ionicons/icons'
import { onMounted, ref } from 'vue'
import { getVideos } from '@/api/api'
import Header from '@/components/header/AppHeader.vue'
import Grid from '@/components/old/Grid.vue'
export default {
  name: 'VideoList',
  components: {
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    Header,
    Grid,
  },
  setup() {
    const videos = ref()
    onMounted(() => {
      getVideos()
        .then(res => (videos.value = res))
        .catch(e => console.error(e))
    })
    const doRefresh = (event: any) => {
      getVideos()
        .then(res => {
          videos.value = res
          event.target.complete()
        })
        .catch(e => console.error(e))
    }
    return {
      ellipsisVertical,
      add,
      videos,
      doRefresh,
    }
  },
}
